









































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { UserModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import Api from "@/api";
import InviteToAppMenuItem from "@/components/common/menu-items/InviteToAppMenuItem.vue";
Vue.component('invite-to-app-menu', InviteToAppMenuItem);

const snackbarModule = getModule(SnackbarModule);

const userHeaders = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<UserModel>("fullname") },
    { text: "Email Address", value: nameof<UserModel>("emailAddress") },
    { text: "App Access", value: nameof<UserModel>("isAccessToApp") },
    { text: "Role", value: nameof<UserModel>("roleDescription") },
    { text: "Agencies", value: nameof<UserModel>("agencyNames") },
    { text: "Failed Logins", value: nameof<UserModel>("loginAttempts") },
    { text: "App Access", value: nameof<UserModel>("isAccessToApp") },
    { text: "Enabled", value: nameof<UserModel>("enabled") },
    { text: "Created", value: nameof<UserModel>("createdUtc") }
]

const crewHeaders = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<UserModel>("fullname") },
    { text: "Email Address", value: nameof<UserModel>("emailAddress") },
    { text: "App Access", value: nameof<UserModel>("isAccessToApp") },
    { text: "Failed Logins", value: nameof<UserModel>("loginAttempts") },
    { text: "App Access", value: nameof<UserModel>("isAccessToApp") },
    { text: "Enabled", value: nameof<UserModel>("enabled") },
    { text: "Created", value: nameof<UserModel>("createdUtc") }
]

@Component
export default class UserTable extends Vue {
  private readonly maxLoginAttempts = 5;
  public loading = false;
  private items: Array<UserModel> = [];
  private headers: Array<DataTableHeader<UserModel>> = this.isCrewUserRoute() ? crewHeaders : userHeaders;

  private async created() {
    await this.getUsers();
  }

  private async getUsers() {
    try {
      this.loading = true;
      const response = await Api.UserService.apiUserGet();
      this.items = response.data;

      if(this.isCrewUserRoute()) {
        this.items = this.items.filter(user => user.role === "CRW");
      } else {
        this.items = this.items.filter(user => user.role !== "CRW");
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve users");
    } finally {
      this.loading = false;
    }
  }

  private async resetLoginAttempts(item: UserModel) {
    if (!item.userId) {
      return;
    }

    try {
      this.loading = true;
      item.loginAttempts = 0;
      await Api.UserService.apiUserUserIdPut(item.userId, item);
    } catch {
      snackbarModule.setSnackbarMessage("Failed to reset login attempts");
    } finally {
      this.loading = false;
    }

    await this.getUsers();
  }

  
  public updateUserLocally(item: UserModel) {
    const index = this.items.findIndex(user => item.userId === user.userId);

    if(index) {
      this.items[index] = item;
    } else {
      this.getUsers();
    }
  }

  public isCrewUserRoute() {
    return this.$route.name === "App Users";
  }
}
