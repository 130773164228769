





















import { Vue, Component } from "vue-property-decorator";
import UserTable from "@/components/user/UserTable.vue";
import UserCreateDialog from "@/components/dialogs/UserCreateDialog.vue";
import UserUpdateDialog from "@/components/dialogs/UserUpdateDialog.vue";
import { UserModel } from "@/api/generated";

@Component({ components: { UserTable, UserCreateDialog, UserUpdateDialog } })
export default class UserOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private updateUserId = "";

  private onCreateUser() {
    this.createDialog = true;
  }

  private onUpdateUser(user: UserModel) {
    if (!user.userId) {
      return;
    }

    this.updateUserId = user.userId;
    this.updateDialog = true;
  }

  public getRouteName() {
    return this.$route.name;
  }
}
