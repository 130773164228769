var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-table"},[_c('base-data-table',{attrs:{"title":_vm.isCrewUserRoute() ? 'Crew' : 'Users',"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"noCreate":_vm.isCrewUserRoute()},on:{"create-item":function($event){return _vm.$emit('create-user')}},scopedSlots:_vm._u([{key:"item.loginAttempts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.loginAttempts)+" / "+_vm._s(_vm.maxLoginAttempts)+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(item.enabled))+" ")]}},{key:"item.createdUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdUtc))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('invite-to-app-menu',{attrs:{"user":item},on:{"loading":function (isLoading) { return _vm.loading = isLoading; },"update-grid":function (user) { return _vm.updateUserLocally(user); }}}),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('update-user', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.resetLoginAttempts(item)}}},[_c('v-list-item-title',[_vm._v("Reset Attempts")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }